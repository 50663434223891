























import { Component, Mixins } from 'vue-property-decorator'

import UserProfileForm from '@/partials/forms/UserProfileForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    UserProfileForm
  }
})
export default class UsersEdit extends Mixins(FlashMessageMixin) {
  redirectToUsers (message: string) {
    const flashMessage = {
      message: message,
      isSuccess: true
    }

    this.$router.push('/users', () => {
      this.setFlashMessage(flashMessage)
    })
  }
}
